@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?41951693');
  src: url('../font/fontello.eot?41951693#iefix') format('embedded-opentype'),
  url('../font/fontello.woff2?41951693') format('woff2'),
  url('../font/fontello.woff?41951693') format('woff'),
  url('../font/fontello.ttf?41951693') format('truetype'),
  url('../font/fontello.svg?41951693#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?41951693#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-1-cat-all-info-civiles:before { content: '\e800'; } /* '' */
.icon-1-objectifs:before { content: '\e801'; } /* '' */
.icon-3-cat-personne-a-charges--enfants:before { content: '\e802'; } /* '' */
.icon-3-cat-personne-a-charges--livret-de-famille:before { content: '\e803'; } /* '' */
.icon-3-cat-personne-a-charges--personnes-a-charges:before { content: '\e804'; } /* '' */
.icon-3-financier:before { content: '\e805'; } /* '' */
.icon-4-biens-divers:before { content: '\e806'; } /* '' */
.icon-4-cat-biens-immo--foncier-forestier:before { content: '\e807'; } /* '' */
.icon-4-cat-biens-immo--locatif:before { content: '\e808'; } /* '' */
.icon-4-cat-biens-immo--rprincipale:before { content: '\e809'; } /* '' */
.icon-4-cat-biens-immo--rsecondaire:before { content: '\e80a'; } /* '' */
.icon-4-cat-biens-immo--scpi:before { content: '\e80b'; } /* '' */
.icon-5-autres:before { content: '\e80c'; } /* '' */
.icon-5-cat-avoirs-financiers:before { content: '\e80d'; } /* '' */
.icon-6-cat-biens-divers:before { content: '\e80e'; } /* '' */
.icon-6-recap:before { content: '\e80f'; } /* '' */
.icon-7-cat-infos-complementaire--credits:before { content: '\e810'; } /* '' */
.icon-7-cat-infos-complementaire--donations-et-testaments:before { content: '\e811'; } /* '' */
.icon-7-cat-infos-complementaire--impots:before { content: '\e812'; } /* '' */
.icon-7-cat-infos-complementaire--prevoyances:before { content: '\e813'; } /* '' */
.icon-changer-type-de-biens-2:before { content: '\e814'; } /* '' */
.icon-delete:before { content: '\e815'; } /* '' */
.icon-icon-commencer-recueil:before { content: '\e816'; } /* '' */
.icon-upload-file:before { content: '\e817'; } /* '' */
.icon-attention:before { content: '\e818'; } /* '' */
