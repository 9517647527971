/* Customizations to the designer theme should be defined here */

/*.p-button.p-button-text,
.p-button.primary.p-button-text, .p-buttonset.p-button-primary>.p-button.p-button-text, .p-splitbutton.p-button-primary>.p-button.p-button-text,
.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary>.p-button.p-button-text, .p-splitbutton.p-button-secondary>.p-button.p-button-text,
.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success>.p-button.p-button-text, .p-splitbutton.p-button-success>.p-button.p-button-text,
.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info>.p-button.p-button-text, .p-splitbutton.p-button-info>.p-button.p-button-text,
.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning>.p-button.p-button-text, .p-splitbutton.p-button-warning>.p-button.p-button-text,
.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger>.p-button.p-button-text, .p-splitbutton.p-button-danger>.p-button.p-button-text,
.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help>.p-button.p-button-text, .p-splitbutton.p-button-help>.p-button.p-button-text,
.p-button.p-button-outlined,
.p-button.p-button-primary.p-button-outlined, .p-buttonset.p-button-primary>.p-button.p-button-outlined, .p-splitbutton.p-button-primary>.p-button.p-button-outlined,
.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary>.p-button.p-button-outlined, .p-splitbutton.p-button-secondary>.p-button.p-button-outlined,
.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success>.p-button.p-button-outlined, .p-splitbutton.p-button-success>.p-button.p-button-outlined,
.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info>.p-button.p-button-outlined, .p-splitbutton.p-button-info>.p-button.p-button-outlined,
.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning>.p-button.p-button-outlined, .p-splitbutton.p-button-warning>.p-button.p-button-outlined,
.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger>.p-button.p-button-outlined, .p-splitbutton.p-button-danger>.p-button.p-button-outlined,
.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help>.p-button.p-button-outlined, .p-splitbutton.p-button-help>.p-button.p-button-outlined
{
  background-color: white;
}*/
.p-button.p-button-text {
  background-color: white;
}
p-button.p-button-raised .p-button {
  background-color: white;
  color: $primaryColor;
}
p-button.p-button-raised .p-button:enabled:hover {
  background-color: $tim-light-grey;
  color: $primaryDarkColor;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  color: $primaryColor;
}

.p-datatable .p-sortable-column:focus, .p-button:focus {
  box-shadow: none;
}

.p-inputtext.ng-touched.ng-invalid,
p-multiselect.ng-touched.ng-invalid .p-multiselect,
p-dropdown.ng-touched.ng-invalid > .p-dropdown,
p-calendar.ng-touched.ng-invalid > .p-calendar > .p-inputtext,
p-chips.ng-touched.ng-invalid > .p-chips > .p-inputtext,
p-autocomplete.ng-touched.ng-invalid > .p-autocomplete > .p-inputtext,
p-inputmask.ng-touched.ng-invalid > .p-inputtext,
p-inputnumber.ng-touched.ng-invalid > .p-inputnumber > .p-inputtext,
p-cascadeselect.ng-touched.ng-invalid > .p-cascadeselect,
p-password.ng-invalid.ng-touched > .p-password > .p-inputtext,
p-treeselect.ng-invalid.ng-touched > .p-treeselect {
  border: 1px solid $dangerColor;
}

input::placeholder {
  color: $tim-dark-grey;
}

.p-fieldset .p-fieldset-legend, .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  border-radius: 2px;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus,
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus
{
  box-shadow: none;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: transparent;
}
/* custom fieldset colors */
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend, .p-fieldset .p-fieldset-legend {
  background-color: $tim-primary;
}
.p-fieldset-toggler, .p-fieldset-legend-text {
  color: white;
}

.p-fieldset.p-fieldset-toggleable.secondary .p-fieldset-legend, .p-fieldset.secondary .p-fieldset-legend {
  background-color: $secondaryColor;
}
.p-fieldset.p-fieldset-toggleable.secondary .p-fieldset-legend:hover {
  border-color: $secondaryColor;
}
.p-fieldset.p-fieldset-toggleable.success .p-fieldset-legend, .p-fieldset.success .p-fieldset-legend {
  background-color: $successColor;
}
.p-fieldset.p-fieldset-toggleable.success .p-fieldset-legend:hover {
  border-color: $successColor;
}
.p-fieldset.p-fieldset-toggleable.info .p-fieldset-legend, .p-fieldset.info .p-fieldset-legend {
  background-color: $infoColor;
}
.p-fieldset.p-fieldset-toggleable.info .p-fieldset-legend:hover {
  border-color: $infoColor;
}
.p-fieldset.p-fieldset-toggleable.warning .p-fieldset-legend, .p-fieldset.warning .p-fieldset-legend {
  background-color: $warningColor;
}
.p-fieldset.p-fieldset-toggleable.warning .p-fieldset-legend:hover {
  border-color: $warningColor;
}
.p-fieldset.p-fieldset-toggleable.help .p-fieldset-legend, .p-fieldset.help .p-fieldset-legend {
  background-color: $helpColor;
}
.p-fieldset.p-fieldset-toggleable.help .p-fieldset-legend:hover {
  border-color: $helpColor;
}
.p-fieldset.p-fieldset-toggleable.danger .p-fieldset-legend, .p-fieldset.danger .p-fieldset-legend {
  background-color: $dangerColor;
}
.p-fieldset.p-fieldset-toggleable.danger .p-fieldset-legend:hover {
  border-color: $dangerColor;
}

a {
  color: $primaryColor;
}

.invalid-feedback {
  font-size: 0.8rem;
  margin: 3px 0;
  color: var(--dangerColor);
}

.p-sidebar {
  background-color: rgba(0, 0, 0, 0.5);
}
.p-divider.p-divider-horizontal .p-divider-content {
  //background-color: var(--secondaryColor);
  //padding: 0.2rem 0.5rem;
  padding: 0 0.5rem;
}

.p-divider-horizontal{
  margin: 5px 0 25px;

}
.desktop .p-sidebar {
  .p-sidebar-header{
    padding: 20px 55px;
  }
}
.mobile .p-sidebar {
  .p-sidebar-header{
    padding: 7px;
  }
}
.p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon {
  background-color: white;
  border-radius: 0;
}
.p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header button {
  height: 2.3rem;
  width: 2.3rem;
}
