@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

h1, h2, h3, h4, h5 {
  font-family: 'Playfair Display', serif;
  color: var(--primary-color);
}

h1.rec, h2.rec, h3.rec, h4.rec, h5.rec {
  margin-bottom:5px;
  font-family: var(--rec-font-family);
  color: var(--primary-color);
  font-weight: 600;
}

h1.rec{
  font-size: 25px;
  font-weight: 600;
}

h2.rec{
  font-size: 25px;
  font-weight: 500;
}

h3.rec{
  font-size: 18px;
  font-weight: 500;
}

h4.rec{
  font-size: 15px;
  font-weight: 500;
}

body {
  font-family: 'Noto Sans', sans-serif;
}
