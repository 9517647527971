// TIM colors
$tim-primary: #076770;
$tim-yellow: #edc450;
$tim-green: #91c021; // Success
$tim-turquoise: #46d1c0; // info
$tim-orange: #f09e45; // Warning
$tim-red: #bd1818; // Danger
$tim-purple: #757faf; // Help
$tim-dark-grey: #c1bdb7;
$tim-grey: #eaeaea;
$tim-light-grey: #f6f6f6;
$tim-grey-light: #ccc;

//REC colors
$rec-info: #e3eeef;
$rec-surface1: #f9feff;
$rec-primary2: #3E8990;
$rec-neutral0: #9bb6ba;
$rec-secondary3: #04393e;
$rec-success: #47D289;
$rec-danger: #EB6554;

// Theme Specific Variables
$primaryColor: $tim-primary;
$primaryDarkColor: #035259;
$primaryDarkerColor: #02464d;
$primaryLighterColor: #B5CDCF;
$primaryTextColor: #ffffff;

$secondaryColor: $tim-yellow;
$infoColor: $tim-turquoise;
$successColor: $tim-green;
$warningColor: $tim-orange;
$helpColor: $tim-purple;
$dangerColor: $tim-red;

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/_data';
@import './variables/_overlay';
@import './variables/_message';
@import './variables/_menu';
@import './variables/_media';
@import './variables/_misc';

@font-face {
  font-family: 'poppins';
  src: url('../../../../../assets/font/Poppins-Regular.ttf');
}

:root {
    --surface-a:#ffffff;
    --surface-b:#f8f9fa;
    --surface-c:#e9ecef;
    --surface-d:#dee2e6;
    --surface-e:#ffffff;
    --surface-f:#ffffff;
    --text-color:#495057;
    --text-color-secondary:#6c757d;
    --primary-color: #{$primaryColor};
    --primary-color-text:#ffffff;
    --font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --rec-font-family:'poppins';
    --surface-0: #ffffff;
    --surface-50: #FAFAFA;
    --surface-100: #F5F5F5;
    --surface-200: #EEEEEE;
    --surface-300: #E0E0E0;
    --surface-400: #BDBDBD;
    --surface-500: #9E9E9E;
    --surface-600: #757575;
    --surface-700: #616161;
    --surface-800: #424242;
    --surface-900: #212121;
    --content-padding:#{$panelContentPadding};
    --inline-spacing:#{$inlineSpacing};
    --border-radius:#{$borderRadius};
    --surface-ground:#f8f9fa;
    --surface-section:#ffffff;
    --surface-card:#ffffff;
    --surface-overlay:#ffffff;
    --surface-border:#dee2e6;
    --surface-hover:#e9ecef;
    --maskbg: #{$maskBg};
    --focus-ring: #{$focusShadow};

  /* TIM Theme Variables */
  --primaryColor: #{$primaryColor};
  --primaryDarkColor: #{$primaryDarkColor};
  --primaryDarkerColor: #{$primaryDarkerColor};
  --primaryLighterColor: #{$primaryLighterColor};
  --primaryTextColor: #{$primaryTextColor};
  --tim-primary: #{$tim-primary};
  --tim-secondary: #{$secondaryColor};
  --tim-success: #{$successColor};
  --tim-info: #{$infoColor};
  --tim-warning: #{$warningColor};
  --tim-danger: #{$dangerColor};
  --tim-help: #{$helpColor};
  --tim-dark-grey: #{$tim-dark-grey};
  --tim-grey: #{$tim-grey};
  --tim-light-grey: #{$tim-light-grey};

  --rec-info: #{$rec-info};
  --rec-surface1: #{$rec-surface1};
  --rec-primary2: #{$rec-primary2};
  --rec-neutral0: #{$rec-neutral0};
  --rec-secondary3: #{$rec-secondary3};
  --rec-success: #{$rec-success};
  --rec-danger: #{$rec-danger};

  --secondaryColor: #{$secondaryColor};
  --infoColor: #{$infoColor};
  --successColor: #{$successColor};
  --warningColor: #{$warningColor};
  --helpColor: #{$helpColor};
  --dangerColor: #{$dangerColor};

}
