.p-card {
    border-radius: 5px;
    border: 1px solid #dbe7f0;
    padding: 10px;
    box-shadow: 0px 12.3576px 37.0728px rgba(0, 0, 0, 0.1);

    background: $panelContentBg;
    color: $panelContentTextColor;
    //box-shadow: $cardShadow;
    //border-radius: $borderRadius;

    .p-card-body {
        //padding: $cardBodyPadding;
        padding: 10px;
    }

    .p-card-title {
        font-size: $cardTitleFontSize;
        font-weight: $cardTitleFontWeight;
        margin-bottom: $inlineSpacing;
    }

    .p-card-subtitle {
        font-weight: $cardSubTitleFontWeight;
        margin-bottom: $inlineSpacing;
        color: $cardSubTitleColor;
    }

    .p-card-content {
        //padding: $cardContentPadding;
        padding: 0;
    }

    .p-card-footer {
        padding: $cardFooterPadding;
    }
}
