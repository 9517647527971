@import "./theme/device";
@import "../../common/src/lib/primeng-designer-13.2.0/src/sass/themes/timTheme/theme";

@import "~primeflex/primeflex.css";
@import "~primeicons/primeicons.css";
@import "~primeng/resources/primeng.min.css";

.p-sidebar-mask{
  background-color: rgba(0, 0, 0, 0);
}
.p-sidebar {
  background-color: rgba(0, 0, 0, 0);
}
span.p-dropdown-label{
  width:0;
}

lib-field-error div {
  font-size: 0.8rem;
}

/*****************************************************
 Phone input style.
*****************************************************/

.iti {
  display: block !important;
  margin-bottom: 20px;
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 244px;
  max-height: 170px;
}

.iti__flag-container.open + input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

ngx-intl-tel-input input {
  font-family: var(--font-family);
  margin-bottom: 20px;
  padding: 0.5rem 0.5rem;
  border-style: solid;
  border-width: 1px;
  border: $inputBorder;
  border-radius: $borderRadius;
  font-size: 1rem;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
  border-color: $inputHoverBorderColor;
}

ngx-intl-tel-input input:focus {
  @include focused-input();
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

.pac-container {
  z-index: 1100;
}
body{
  font-family: var(--font-family);
  margin: 0;
  overflow-x: hidden;
}

.p-datatable-tbody > tr > td {
  cursor: pointer;
}

.p-fieldset-legend{
  .pi-plus:before {
    content: "\e932";
  }
  .pi-minus:before {
    content: "\e930";
  }
}

.p-datepicker table td {
  padding: 2px;
}
.p-datepicker table th{
  padding: 0;
}
.p-datepicker table td > span {
  width: 22px;
  height: 22px;
}
.p-datepicker-title {
  .p-link {
    font-size: 0.9rem;
  }
}

.text-danger, .text-danger:enabled:hover {
  color: var(--dangerColor);
}
.text-from-editor{
  p{
    margin: 0;
  }
}
.tag-nowrap{
  .p-tag-value{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  }
}

.p-tag.p-tag-rounded {
  border-radius: 10rem;
}

.p-menuitem-link{
  cursor: pointer;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--rec-info);
}
p-toast{
  .p-toast {
    width: auto;
  }
}

.link {
  color: $primaryColor;
  text-decoration: underline;
  cursor: pointer;
}

.disabled-link {
  color: $tim-dark-grey;
  text-decoration: underline;
  cursor: unset;
}

.p-datatable-reorderablerow-handle::before{
  font-size: 26px;
  color: var(--primary-color);
}

.p-checkbox-label{
  cursor:pointer;
}

.p-checkbox-label{
  cursor:pointer;
}

.p-disabled, .p-component:disabled{
  opacity: 0.5;
}

.rec{
  .p-button {
    border-radius: 6px;
  }
  font-family: var(--rec-font-family);
  .p-component, .p-inputtext, .custom{
    font-family: var(--rec-font-family);
  }

  .p-accordion p-accordiontab:last-child .p-accordion-content{
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link,
  .p-dialog{
    border-radius: 6px;
  }
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    h3.rec{
      margin: 0;
    }
  }

  .p-accordion .p-accordion-header .p-accordion-header-link,
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
  .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
  .p-dialog .p-dialog-header {
    background: var(--rec-info);
    color: var(--primaryColor);
  }

  .p-accordion p-accordiontab .p-accordion-tab{
    -webkit-box-shadow: 5px 5px 20px 5px rgba(75,123,130,0.5);
    box-shadow: 5px 5px 20px 5px rgba(75,123,130,0.5);
    border-radius: 6px;
  }

  .p-accordion .p-accordion-content{
    padding: 1px 2rem;
  }

  .p-dialog .p-dialog-header{
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom : none;
  }

  .p-dialog .p-dialog-header .p-dialog-title{
    font-family: var(--rec-font-family);
    font-weight: 500;
  }

  .p-dialog .p-dialog-footer{
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top : none;
  }
}

.p-accordion-header-link{
  h3.rec{
    margin: 0;
  }
  .p-button{
    box-shadow: none;
    background-color: transparent;
  }
}
.p-tooltip {
  max-width: 400px;
}
.custom-tooltip{
  .p-tooltip-text{
    background: var(--primaryColor);
    border-radius: 6px;
  }
}

.p-divider.p-divider-horizontal:before{
  border-top: 1px #e3eeef solid;
}
